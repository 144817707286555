.select_image {
  height: 50px;
  width: 50px;
  border-radius: 10px;
  border: 2px solid rgb(94, 94, 94);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  position: relative;
  color: rgb(94, 94, 94);
}

.select_image input {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

