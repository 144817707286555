.content_main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content_container {
  width: 100%;
  display: flex;
  align-items: center;
  background: #ececec;
  padding: 5px 15px;
}

.content_container h1 {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.08rem;
}

.content_body_container {
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  padding: 10px 15px;
}

.select_image {
  height: 50px;
  width: 50px;
  border-radius: 10px;
  border: 2px solid rgb(94, 94, 94);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  position: relative;
  color: rgb(94, 94, 94);
}

.select_image input {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
