.applayout_container {
	height: 100vh;
	width: 100vw;
	display: flex;
	overflow: hidden;
}

/* **********NAV BAR START********** */
.nav_container {
	height: 50px;
	font-size: 1.6rem;
	font-weight: 600;
	padding-left: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	padding-right: 3vw;
	border-radius: 10px;
}

.nav_container span {
	color: white;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
		1px 1px 0 #000;
	letter-spacing: 2px;
}

.nav_container button {
	border: none;
	background: none;
	font-size: 18px;
	font-weight: 500;
	cursor: pointer;
}
/* **********NAV BAR END********** */

.sidebar_and_content_container {
	flex-grow: 1;
	height: 100vh;
	overflow: hidden;
	padding: 11px;
}

.sidebar_container {
	width: 65px;
	height: 100%;
	position: relative;
	padding-top: 10px;
	padding-bottom: 14px;
	padding-left: 10px;
}

.sidebar_text {
	display: none;
	padding-left: 15px;
	transition: 0.5s;
}

.sidebar_text_show {
	display: inline-block;
}

.sidebar_main {
	position: relative;
	width: 55px;
	height: 100%;
	left: 0;
	background: rgb(236, 236, 236);
	display: flex;
	flex-direction: column;
	transition: 0.5s;
	overflow: hidden;
	z-index: 100;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	padding-top: 5px;
}

.content_container {
	width: 100%;
	height: calc(100vh - 24px);
	/* padding: 0 20px 20px 20px; */
	overflow: hidden;
	padding: 0px;
	border-radius: 10px;
	margin-top: 0px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* **********MENU ICON START********** */
.menu_icon_container {
	width: 55px;
	height: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 7px;
	cursor: pointer;
	overflow: hidden;
	/* padding-block: 5px; */
}

.menu_line_one,
.menu_line_two,
.menu_line_three {
	height: 2px;
	width: 27px;
	background: rgb(74, 74, 74);
	border-radius: 50px;
	transition: 0.5s;
	transform-origin: center left;
}

.open_menu_icon .menu_line_one {
	transform: rotateZ(45deg);
}

.open_menu_icon .menu_line_two {
	transform: rotateY(-90deg);
}

.open_menu_icon .menu_line_three {
	transform: rotateZ(-45deg);
}
/* **********MENU ICON END********** */

.menu_items_container {
	/* border: 1px solid green; */
	list-style: none;
	width: 100%;
	height: 100%;
	padding: 0 3px;
	position: relative;
	display: grid;
	justify-content: space-between;
	padding-bottom: 50px;
	/* margin-top: 20px; */
}

.menu_items_container li {
	/* border: 1px solid red; */
	width: 100%;
	height: 43px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	font-size: 16px;
	border-radius: 10px;
	padding: 0 13px;
	margin-bottom: 5px;
}

.menu_items_container li:last-child {
	position: absolute;
	bottom: 1px;
	width: calc(100% - 6px);
}

.menu_items_container li svg {
	height: 22px;
	width: 18px;
	padding: 0 !important;
	margin: 0 !important;
}

.menu_items_container li svg path {
	fill: rgb(48, 48, 48) !important;
}

.menu_items_container li:hover {
	background: rgba(0, 0, 0, 0.05);
}

.menu_items_container li:hover svg path {
	fill: rgb(103, 103, 103) !important;
}

.menu_items_container li:hover svg {
}

.selected {
	border: 1px solid rgba(0, 0, 0, 0.3);
	background: rgba(0, 0, 0, 0.05);
}

.selected svg path {
	fill: white !important;
}

.icon_color path {
	fill: orange !important;
}
