.field_contianer {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	gap: 5px;
}

.field_contianer p {
	font-size: 16px;
	font-weight: 500;
	transform: translateX(5px);
}

.select_container {
	height: fit-content;
	width: 100%;
	font-size: 18px;
	background-color: transparent;
	position: relative;
}

.select_container svg {
	position: absolute;
	z-index: 1;
	top: 50%;
	right: 2px;
	transform: translateY(-50%);
	height: 20px;
	width: 50px;
}

.select_container select {
	height: 60px;
	width: 100%;
	border: 1px solid grey;
	padding: 10px;
	font-size: 18px;
	position: relative;
	appearance: none;
	-webkit-appearance: none;
	background: transparent;
	border-radius: 15px;
	z-index: 5;
}

.select_container select:focus {
	outline: 1px solid grey;
}

.select_container option {
	text-transform: uppercase;
}

.field_contianer input {
	height: 60px;
	border-radius: 15px;
	border: 1px solid grey;
	padding: 10px;
	font-size: 18px;
	background-color: transparent;
	overflow: hidden;
}

.textarea {
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	font-size: 18px !important;
}

.field_contianer input:focus {
	outline: 1px solid grey;
}
