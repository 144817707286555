.container {
	width: 100vw;
	height: 100vh;
	display: grid;
	grid-template-columns: 33vw 1fr;
	position: relative;
	background: rgb(235, 235, 235);
	padding-top: 20px;
	display: flex;
	justify-content: center;
}

.container img {
	height: 70px;
	border-radius: 15px;
}

.modal {
	position: absolute;
	height: fit-content;
	width: 600px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	border-radius: 15px;
	background: white;
	overflow: hidden;
	padding-bottom: 30px;
}

.modal h1 {
	font-size: 2rem;
	text-align: center;
	padding: 20px;
	font-weight: 500;
	color: #072d19;
}

.form {
	display: grid;
	grid-template-columns: 1fr;
	padding: 0 50px;
	gap: 15px;
}

.form input,
.form button {
	height: 50px;
	border-radius: 10px;
	border: 1px solid #072d19;
	padding: 10px;
}

.form input:focus {
	outline: 2px solid #072d19;
}

.form button {
	background: #072d19;
	color: white;
	font-weight: 500;
	font-size: 1.1rem;
}
